<template>
  <v-card class="pa-8" width="100%">
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <h3 class="pa-4" style="font-weight: 800">รายละเอียดออเดอร์</h3>
      <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span><b>ชื่อผู้สั่ง</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ name }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span><b>ที่อยู่</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ address }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span><b>หมายเลขโทรศัพท์</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ tel }}</span>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" md="3" sm="3"><span>สั่งเมื่อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ convertDate(createdAt) }}</span>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span><b>หมายเลขออเดอร์</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ orderNo }}</span>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" md="3" sm="3"><span>ประเภทการรับออเดอร์</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ orderType }}</span>
        </v-col>
      </v-row> -->
      <!-- <v-row>
        <v-col cols="12" md="3" sm="3"><span><b>สถานะออเดอร์</b></span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <v-chip v-if="orderStatus == 'WAITING_PAYMENT'" color="blue" dark
            >รอชำระเงิน</v-chip
          >
          <v-chip v-if="orderStatus == 'WAITING'" color="yellow"
            >WAITING</v-chip
          >
          <v-chip v-if="orderStatus == 'SUCCESS'" color="green" dark
            >ชำระเงินสำเร็จ</v-chip
          >
          <v-chip v-if="orderStatus == 'CANCEL'" color="red" dark
            >ยกเลิก</v-chip
          >
          <v-chip v-if="orderStatus == 'DERIVERED'">ส่งแล้ว</v-chip>
          <v-chip v-if="orderStatus == 'PAID'">PAID</v-chip>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span><b>วันที่สั่งของ</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ convertDate(orderdate) }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span><b>ราคาที่ชำระ</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ convertComma(totalPrice) }} บาท</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span><b>ค่าจัดส่ง</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ convertComma(deliveryPrice) }} บาท</span>
        </v-col>
      </v-row>
      <!-- isUseCoupon:false,
      couponName:"",
      couponAmt: null,
      isUseVoucher:false,
      voucherAmt:null, -->
      <v-row v-if="isUseCoupon">
        <v-col cols="12" md="3" sm="3"
          ><span><b>คูปองที่ใช้</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ convertComma(couponAmt) }} บาท </span>
          (<span>{{ couponName }}</span
          >)
        </v-col>
      </v-row>
      <!-- <v-row v-if="isUseCoupon">
      <v-col cols="12" md="3" sm="3"><span><b>ชื่อคูปอง</b></span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span></span>
        </v-col>
      </v-row> -->
      <v-row v-if="isUseVoucher">
        <v-col cols="12" md="3" sm="3"
          ><span><b>มูลค่าบัตรเงินสด</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ convertComma(voucherAmt) }} บาท</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span><b>ประเภทการจ่ายเงิน</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ paymentType }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span><b>รหัสชำระเงิน</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ paymentTransactionId }}</span>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" md="3" sm="3"><span><b>สถานะการจ่ายเงิน</b></span> </v-col>
      <v-col cols="12" md="9" sm="9">
          <span>{{ orderStatus }}</span>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span><b>สถานะออเดอร์</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <v-chip v-if="orderStatus == 'WAITING_PAYMENT'" color="blue" dark
            >รอชำระเงิน</v-chip
          >
          <v-chip v-if="orderStatus == 'WAITING'" color="yellow"
            >WAITING</v-chip
          >
          <v-chip v-if="orderStatus == 'SUCCESS'" color="green" dark
            >ชำระเงินสำเร็จ</v-chip
          >
          <v-chip v-if="orderStatus == 'CANCEL'" color="red" dark
            >ยกเลิก</v-chip
          >
          <v-chip v-if="orderStatus == 'DERIVERED'">ส่งแล้ว</v-chip>
          <v-chip v-if="orderStatus == 'PAID'">PAID</v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span></span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <v-select
            v-model="orderStatus"
            :items="paymentStatusItems"
            solo
            dense
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="orderStatus == 'DERIVERED'">
        <v-col cols="12" md="3" sm="3" class="mt-2"
          ><span><b>หมายเลขจัดส่ง</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <!-- <span>{{ paymentTransactionId }}</span> -->
          <v-text-field
            v-model="deliveryNo"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"
          ><span><b>ชื่อผู้รับ</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ name }}</span>
        </v-col>
      </v-row>
      <v-row v-if="slipImg">
        <v-col cols="12" md="3" sm="3"
          ><span><b>รูป slip</b></span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <v-img :src="slipImg" max-width="400px" max-height="400px"></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-btn class="text-right" @click="exportVat()">ออกใบเสร็จรับเงิน</v-btn>
      </v-row>
      <h3 class="pt-8" style="font-weight: 800">ออเดอร์ที่สั่ง</h3>
      <v-data-table
        :headers="headers"
        :items="orderProducts"
        :items-per-page="15"
        class="elevation-1"
      >
        <template v-slot:[`item.product.imgUrl`]="{ item }">
          <img class="pa-1" :src="item.product.imgUrl" height="80px" />
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-row justify="center">
            <v-icon @click="viewProduct(item.product)">mdi-eye</v-icon>
          </v-row>
        </template>
        <!-- <template v-slot:[`item.product.price`]="{ item }">
          <v-row justify="center">
            <span>{{ CommaFunction(item.product.price) }}</span>
          </v-row>
        </template> -->
      </v-data-table>
      <!-- <v-row v-for="(i, index) in orderProducts" :key="index">
        <v-col cols="2">{{ i.product.productName }}</v-col>
        <v-col cols="2">{{ i.product.description }}</v-col>
        <v-col cols="2"><img :src="i.product.imgUrl" width="100px" /></v-col>
        <v-col cols="2">{{ i.amountTypePrice }} บาท</v-col>
        <v-col cols="1"> {{ i.amount }} {{ i.amountType }}</v-col>
        <v-col cols="1"> {{ i.note }}</v-col>
      </v-row> -->

      <br />
      <br />
      <v-row>
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="submit()">บันทึก</v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { Decode, Encode } from "@/services";
import moment from "moment";
// var numeral = require("numeral");

// Vue.filter("formatNumber", function (value) {
//   return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
// });

export default {
  data() {
    return {
      // coupon: {id: 1, couponName: "คูปอง1", couponCode: "coupon1", couponType: "ลดตามหมวดหมู่", discountPrice: 200, …}
      // couponId: 1
      // createdAt: "2021-07-14T11:19:27.000Z"
      // id: 1
      // name: "Ittiwut Wongsawat"
      createdAt: "",
      addressDetail: "",
      subDistrict: "",
      district: "",
      province: "",
      postalCode: "",

      // userId: 1
      address: "",
      approveFlag: true, //////////////////////////////
      count: 1,
      id: "",
      orderNo: "",
      orderType: "",
      orderdate: "",
      orderStatus: "",
      paymentType: "",
      paymentStatus: "", //////////////////////////////
      paymentTransactionId: "", //////////////////////////////
      status: "", //////////////////////////////
      deliveryPrice: null,
      slipImg: "",
      updatedAt: "", //////////////////////////////
      deliveryNo: "",
      itemsStatus: [
        { text: "ยืนยันแล้วรอชำระเงิน", value: "ยืนยันแล้วรอชำระเงิน" },
        { text: "ถูกจัดส่งแล้ว", value: "ถูกจัดส่งแล้ว" },
      ],
      tel: "",
      totalPrice: null,
      totalPrice: null,
      userId: null,
      isUseCoupon: false,
      couponName: "",
      couponAmt: null,
      isUseVoucher: false,
      voucherAmt: null,

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      name: "",
      // fullname: "",
      // firstname: "",
      // lastname: "",
      orderProducts: [],
      headers: [
        { text: "sku", value: "product.sku" },
        { text: "ชื่อสินค้า", value: "product.productName" },
        // { text: "รายละเอียดสินค้า", value: "product.description" },
        { text: "รูปสินค้า", value: "product.imgUrl" },
        { text: "ราคา", value: "product.price" },
        { text: "จำนวน", value: "amount" },
        { text: "ตัวเลือก", value: "action", align: "center" },
        // { text: "จำนวน", value: "count" },
        // { text: "หมายเหตุ", value: "note" },
      ],
      paymentStatusItems: [
        { text: "รอชำระเงิน", value: "WAITING_PAYMENT" },
        // { text: "WAITING", value: "WAITING" },
        { text: "ชำระเงินสำเร็จ", value: "SUCCESS" },
        { text: "ยกเลิก", value: "CANCEL" },
        { text: "รอจัดส่ง", value: "WAITING_DELIVERY" },
        { text: "ส่งแล้ว", value: "DERIVERED" },
        // { text: "PAID", value: "PAID" },
      ],
    };
  },
  created() {
    var orderdata = JSON.parse(
      Decode.decode(localStorage.getItem("orderdata"))
    );

    // console.log("orderdata", orderdata);
    this.id = orderdata.id;

    this.getOrderById();
  },
  methods: {
    exportVat() {
      // window.open("/ExportVat");
      window.open("ExportVat?id=" + this.id);
      // this.$router.push({ path: 'ExportVat', query: { id: this.id } })
    },
    convertComma(val) {
      return new Intl.NumberFormat().format(val);
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async getOrderById() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/orders/` + this.id
      );
      console.log("responseById", response);
      const data = response.data.data;
      this.name = data.name;
      this.tel = data.tel;
      this.address =
        data.addressDetail +
        " " +
        data.subDistrict +
        " " +
        data.district +
        " " +
        data.province +
        " " +
        data.postalCode;
      this.createdAt = data.createdAt;
      this.orderNo = data.orderNo;
      this.orderType = data.orderType;
      this.orderStatus = data.orderStatus;
      // this.orderdate = new Date(data.orderdate).toISOString().slice(0, 10);
      this.orderdate = data.orderdate;
      this.totalPrice = data.totalPrice;
      this.totalPrice = data.totalPrice;
      this.deliveryPrice = data.deliveryPrice;
      this.paymentType = data.paymentType;
      this.paymentTransactionId = data.paymentTransactionId;
      this.status = data.status;
      this.slipImg = data.slipImg;
      this.deliveryNo = data.deliveryNo;
      this.isUseCoupon = data.isUseCoupon;
      if (data.isUseCoupon) {
        this.couponName = data.coupon.couponName;
      }
      this.couponAmt = data.couponAmt;
      this.isUseVoucher = data.isUseVoucher;
      this.voucherAmt = data.voucherAmt;
      this.orderProducts = data.orderProducts;
      for (let i in this.orderProducts) {
        this.orderProducts[i].count =
          this.orderProducts[i].amount + " " + this.orderProducts[i].amountType;
        this.orderProducts[i].product.price = new Intl.NumberFormat().format(
          data.orderProducts[i].product.price
        );
      }
      this.paymentStatus = data.paymentStatus;
      // {text: 'รอชำระเงิน', value:'WAITING_PAYMENT'},
      // {text: 'WAITING', value:'WAITING'},
      // {text: 'ชำระเงินสำเร็จ', value:'SUCCESS'},
      // {text: 'ยกเลิก', value:'CANCEL'},
      // {text: 'ส่งแล้ว', value:'DERIVERED'},
      // {text: 'PAID', value:'PAID'},
      if (data.paymentStatus == "WAITING_PAYMENT") {
        this.paymentStatusItems = [
          { text: "ตีกลับ", value: "REJECT" },
          { text: "ชำระเงินสำเร็จ", value: "SUCCESS" },
        ];
      }
      if (data.paymentStatus == "SUCCESS") {
        this.paymentStatusItems = [
          { text: "จัดส่งแล้ว", value: "DERIVERED" },
          { text: "ยกเลิก", value: "CANCEL" },
        ];
      }
      // if (data.paymentStatus == "WAITING_DELIVERY") {
      //   this.paymentStatusItems = [
      //     { text: "ยืนยัน/จัดส่งแล้ว", value: "SUCESS" },
      //   ];
      // }
    },

    cancel() {
      this.$router.push("ManageOrder");
    },
    // ลูกค้าสั่ง >  รอการยืนยัน
    // พนง กดคอนเฟิม >  ยืนยันแล้วรอชำระเงิน
    // ลูกค้าจ่ายเงิน (แนบสลิป) > ชำระเงินแล้วรอจัดเตรียมสินค้า
    // พนง กดส่งของแล้ว > ถูกจัดส่งแล้ว
    // step1() {
    //   this.status = "ยืนยันแล้วรอชำระเงิน";
    //   console.log(this.status);
    //   this.submit();
    // },
    // step2() {
    //   this.status = "ถูกจัดส่งแล้ว";
    //   console.log(this.status);
    //   this.submit();
    // },
    // step3() {
    //   this.status = "ยืนยันสินค้าให้ลูกค้ามารับของ";
    //   console.log(this.status);
    //   this.submit();
    // },
    // stepback() {
    //   this.status = "ยืนยันแล้วรอชำระเงิน";
    //   this.submit();
    // },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const data = {
          id: this.id,
          orderStatus: this.orderStatus,
          // slipImg: "",
          deliveryNo: this.deliveryNo,
        };
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userSPM"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/orders/` + this.id,
          data,
          auth
        );
        console.log("update", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `อัพเดตข้อมูลสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageOrder");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `อัพเดตข้อมูลไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    viewProduct(val) {
      console.log(val);
      localStorage.setItem("productdata", Encode.encode(val));
      // this.$router.push("viewProduct");
      window.open("viewProduct");
    },
  },
};
</script>